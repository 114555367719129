import React from 'react';
import mission1 from '../images/mission_1.jpg';
import mission2 from '../images/mission_2.jpg';
import mission3 from '../images/mission_3.jpg';
import { Grid, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import '../styles/AboutUs.scss';

function AboutUs() {
  return (
    <div className="about-us">
      <div className="about-us__container">
        <div className="about-us__title">Tentang Kami</div>
        <div className="about-us__gcg">Visi dan Misi Kami</div>
        <Grid className="about-us__content">
          <Grid.Row columns={3}>
            <div className="about-us__mission">
              <div className="ui card">
                <Image src={mission1} />
                <div className="about-us__text">
                  <div>
                    Menjadi perusahaan pembiayaan terdepan, terstabil dan
                    terpercaya di Indonesia.
                  </div>
                </div>
              </div>
              <div className="ui card">
                <Image src={mission2} />
                <div className="about-us__text">
                  <div>
                    Menyediakan pembiayaan untuk keperluan masyarakat Indonesia
                    yang aman, mudah, cepat, terjangkau dan terpercaya.
                  </div>
                </div>
              </div>
              <div className="ui card">
                <Image src={mission3} />
                <div className="about-us__text">
                  <div>
                    Berkontribusi secara positif bagi bangsa dan negara terutama
                    di dalam hal inklusi keuangan
                  </div>
                </div>
              </div>
            </div>
          </Grid.Row>
        </Grid>

        <div className="about-us__gcg">
          Good Corporate Governance PT Commerce Finance
        </div>
        <div className="gcg__content">
          <div>
            Commerce Finance berkomitmen untuk memenuhi dan menerapkan prinsip
            Good Corporate Governance (GCG) sesuai dengan Peraturan Otoritas
            Jasa Keuangan tentang Tata Kelola Perusahaan yang Baik bagi
            Perusahaan Pembiayaan. Perusahaan berupaya untuk menerapkan 5
            prinsip utama dalam GCG yaitu keterbukaan, akuntabilitas,
            pertanggungjawaban, kemandirian dan kesetaraan dan kewajaran.
            Prinsip-prinsip tersebut tercermin dari struktur dan infrastruktur
            GCG dan proses penerapan prinsip GCG perusahaan, diantaranya adalah
            pelaksanaan tugas dan tanggung jawab Komisaris dan Direksi
            Perusahaan, penerapan fungsi kepatuhan, penerapan manajemen risiko
            dan sistem pengendalian internal, transparansi kondisi keuangan dan
            non-keuangan dan proses penyusunan rencana bisnis perusahaan.
          </div>
          <div>
            Kami percaya implementasi GCG akan membantu perusahaan dalam
            mencapai tujuan bisnis dan memberikan manfaat yang dapat dirasakan
            oleh seluruh stakeholder kami. Hubungan yang baik dengan stakeholder
            senantiasa dipupuk oleh Perusahaan sehingga penyempurnaan GCG dapat
            dilakukan secara terus menerus.{' '}
          </div>
        </div>
        <div className="about-us__gcg">
          <Link to="/tim-kami">
            <div className="footer__subcontact">
              <div>TIM KAMI </div>
            </div>
          </Link>

          <a href="/pdf/CF Sustainability Report 290424.pdf" download>
            <div className="footer__subcontact">
              <div>LAPORAN PUBLIKASI</div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
