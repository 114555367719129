import React from 'react';
import Accordion from '../components/Accordion'
import "../styles/Faq.scss";
import { faq } from "../helper/constant"

function Faq() {

  return (
    <div className="Faq">
      <div className="Faq__container">
        <div className="Faq__title">FAQ</div>
        <div className="Faq__content">
          {faq.map((data, idx) => (
            <Accordion question={data.question} ans={data.answer} list={data.list} id={data.id} key={idx} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Faq
