interface IOurTeam {
  position: string;
  name: string;
}

export const ourTeamList: IOurTeam[] = [
  {
    position: 'President Director',
    name: 'Winson Winata',
  },
  {
    position: 'Director',
    name: 'Chindras Rivirgo',
  },
  {
    position: 'Director',
    name: 'Anggie Setia Ariningsih',
  },
  {
    position: 'Independent Commissioner',
    name: 'Dhini Laswita',
  },
  {
    position: 'Commissioner',
    name: 'Christin',
  },
];
