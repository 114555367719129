import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Image } from 'semantic-ui-react';

import '../styles/Footer.scss';

function Footer() {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="footer">
      <Grid stackable>
        <Grid.Row columns={3}>
          <Grid.Column textAlign="center">
            <div className="footer__title">PT COMMERCE FINANCE</div>
            <div className="footer__url">
              {/* <Link onClick={handleScrollToTop} to="/tnc">Syarat & Ketentuan</Link> */}
              <Link onClick={handleScrollToTop} to="/faq">
                <div className="footer__subcontact">
                  <i className="fa fa-question-circle" aria-hidden="true" />
                  <div>FAQ </div>
                </div>
              </Link>
              <Link onClick={handleScrollToTop} to="/about-us">
                <div className="footer__subcontact">
                  <i className="fa fa-info-circle" aria-hidden="true" />
                  <div>Tentang Kami </div>
                </div>
              </Link>
              <a
                href="/pdf/CF - Prosedur-Layanan-Pengaduan-Pelanggan-SPL.docx.pdf"
                download
              >
                <div className="footer__subcontact">
                  <i className="fa fa-comments" aria-hidden="true" />
                  <div>Layanan Pengaduan Pelanggan </div>
                </div>
              </a>
            </div>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <div className="footer__title">ALAMAT</div>
            <div className="footer__location">
              <div>Sopo Del Tower Lt 32,</div>
              <div>Jalan Mega Kuningan Barat III Lot 10.1-6, RT.3/RW.3,</div>
              <div>Kuningan Tim., Kecamatan Setiabudi,</div>
              <div>Daerah Khusus Ibukota Jakarta 12950</div>
            </div>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <div className="footer__title">HUBUNGI KAMI</div>
            <table className="table-content">
              <tbody>
                <tr>
                  <td>
                    <div className="footer__contact">
                      <div className="footer__subcontact">
                        <i className="fa fa-phone" />
                        <div>(021) 8060 4253</div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="footer__contact">
                      <div className="footer__subcontact">
                        <i className="fa fa-envelope" />
                        <a href="mailto:customerservice@cmf.co.id">
                          customerservice@cmf.co.id
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="footer__contact">
                      <a
                        href="https://www.linkedin.com/company/pt-commerce-finance/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div>Linked</div>
                        <i className="fa fa-linkedin-square" />
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div className="footer__watcher">
        <div className="footer__title">
          <p>
            PT Commerce Finance terdaftar dan diawasi oleh Otoritas Jasa
            Keuangan (OJK)
          </p>
          <p>
            PT Commerce Finance telah tergabung dalam Asosiasi Perusahaan
            Pembiayaan Indonesia (APPI)
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
