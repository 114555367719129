import React from 'react';
import './Accordion.scss'

const Accordion = (props: { question: string, ans: Array<string>, list?: Array<string>, id: string }) => {
    const { question, ans, list, id } = props;

    let bulletedList= null;

    if(list){
      bulletedList= list.map((data, idx)=>(
        <li key={idx}>{data}</li>
      ))
    }

    return (
        <div className="tabs">
            <div className="tab">
                <input type="checkbox" id={id} />
                <label className="tab-label" htmlFor={id}>{question}</label>
                <div className="tab-content">
                    {ans.map((data, idx) => (
                        <div className="accordion__answer" key={idx}>
                            {data}
                        </div>
                    ))}
                    { 
                      bulletedList ? ( <ul>{bulletedList}</ul> ) : null
                    }
                </div>
            </div>
        </div>
    )
}

export default Accordion
