import React from 'react';
import { Item } from 'semantic-ui-react';
import { products } from "../helper/constant";
import ProductList from "../components/ProductList"
import "../styles/Product.scss";

function Product() {
  return (
    <div className="product">
      <div className="product__container">
        <div className="product__title">Produk Kami</div>
        <Item.Group>
          {products.map((data, idx) => (
            <ProductList image={data.image}
              productName={data.productName}
              description={data.description}
              multi={products.length === 1 ? false : true}
              key={idx} />
          ))}
        </Item.Group>
      </div>
    </div >
  )
}

export default Product;