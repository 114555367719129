
import React from 'react';
import { Item } from 'semantic-ui-react';
import '../styles/OurTeam.scss';
import { ourTeamList } from '../utils/ourTeamUtils';

function OurTeam() {
  return (
    <div className="ourteam">
      <div className="ourteam__container">
        <div className="ourteam__title">Tim Kami</div>
        <Item.Group>
          {ourTeamList.map((team, idx) => (
            <Item key={idx}>
              <Item.Content>
                <Item.Header>{team.name}</Item.Header>
                <Item.Description>{team.position}</Item.Description>
              </Item.Content>
            </Item>
          ))}
        </Item.Group>
      </div>
    </div>
  );
}

export default OurTeam;
