import React from 'react';
import { Item } from 'semantic-ui-react';
import "../styles/Product.scss";

interface IProps {
    image: string;
    productName: string;
    description: Array<string>;
    multi: boolean;  
}

const ProductList = (props: IProps) => {
    const { image, productName, description, multi } = props
    return (
        <Item className={`product__item ${multi ? " product__item__HR":""}`}>
            <Item.Image size="small" src={image} />
            <Item.Content>
                <Item.Header className="product__name">{productName}</Item.Header>
                <Item.Description className="product__description">
                    {description.map((data, idx) => (
                        <div key={idx}>
                            {data}
                        </div>
                    ))}
                </Item.Description>
            </Item.Content>
        </Item>
    )
}

export default ProductList