import React from 'react';
import { Route, Switch } from 'react-router-dom';

import './App.css';
import 'semantic-ui-css/semantic.min.css';
import Header from './container/Header';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Product from './pages/Product';
import Faq from './pages/Faq';
import OurTeam from './pages/OurTeam';
import Footer from './container/Footer';
// import TnC from './pages/TnC'

function App() {
  return (
    <div className="App">
      <Header />
      <Switch>
        <Route exact path={'/'} component={Home} />
        <Route exact path={'/about-us'} component={AboutUs} />
        <Route exact path={'/product'} component={Product} />
        <Route exact path={'/tim-kami'} component={OurTeam} />
        {/* <Route exact path={'/tnc'} component={TnC} /> */}
        <Route exact path={'/faq'} component={Faq} />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
