import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Image } from 'semantic-ui-react';

import mission1 from '../images/mission_1.jpg';
import mission2 from '../images/mission_2.jpg';
import mission3 from '../images/mission_3.jpg';
import banner from '../images/home_banner.jpg';
import '../styles/Home.scss';

function Home() {
  return (
    <div className="home">
      <div className="home__contact">
        <i className="fa fa-phone"> Pelayanan Pelanggan: (021) 8060 4253</i>
      </div>
      <div className="home__header-image">
        <div className="home__title title__banner">
          <div>PT Commerce Finance adalah perusahaan Multifinance yang memberikan produk pinjaman untuk SPayLater.</div>
        </div>
        <Link to="/faq">Read More</Link>
        <Image src={banner} />
      </div>
      <div className="home__container">
        <div className="home__title">Visi dan Misi Kami</div>
        <Grid className="home__content">
          <Grid.Row columns={3}>
            <div className="home__mission">
              <div className="ui card">
                <Image src={mission1} />
                <div className="home__text">
                  <div>Menjadi perusahaan pembiayaan terdepan, terstabil dan terpercaya di Indonesia.</div>
                </div>
              </div>
              <div className="ui card">
                <Image src={mission2} />
                <div className="home__text">
                  <div>Menyediakan pembiayaan untuk keperluan masyarakat Indonesia yang aman, mudah, cepat, terjangkau dan terpercaya.</div>
                </div>
              </div>
              <div className="ui card">
                <Image src={mission3} />
                <div className="home__text">
                  <div>Berkontribusi secara positif bagi bangsa dan negara terutama di dalam hal inklusi keuangan</div>
                </div>
              </div>
            </div>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
}

export default Home;
