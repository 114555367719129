import React from 'react';
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import logo from '../images/company-logo-removebg.png';
import '../styles/Header.scss';

function Header() {
  const handleClickContactUs = () => {
    const elem = document.querySelector('.footer');
    elem?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="header">
      <Menu fluid borderless widths={4}>
        <Menu.Item name="Logo" active={false} as={Link} to={'/'}>
          <img src={logo} alt="Commerce Finance" />
        </Menu.Item>
        <Menu.Item
          name="tentang kami"
          active={false}
          as={Link}
          to={'/about-us'}
        />
        <Menu.Item name="product" active={false} as={Link} to={'/product'} />
        <Menu.Item
          name="hubungi"
          active={false}
          onClick={handleClickContactUs}
        />
      </Menu>
    </div>
  );
}

export default Header;
